import type {UserInfo, ErrorMessageMode} from '@pkg/types';
import type {GetUserInfoModel, LoginParams} from '@pkg/apis/sys';
import {defineStore} from 'pinia';
import {RoleEnum, PageEnum} from '@pkg/tokens';
import {isArray} from '@pkg/utils';
import {useI18n} from '@pkg/locale';
import {pinia} from '@/internal';
import {getUserInfo, loginApi} from '@pkg/apis/sys';
import {useMessage} from '@/hooks/web/useMessage';
import {router} from '@/router';
import {usePermissionStore} from '@/store/permission';
import {PAGE_NOT_FOUND_ROUTE} from '@/router/routes/basic';
import {h} from 'vue';
import {getSchoolNameApi} from '@pkg/apis/tiku/tiku';
import localForage from 'localforage';

interface UserState {
    userInfo: Nullable<UserInfo>;
    token?: string;
    roleList: RoleEnum[];
    sessionTimeout?: boolean;
    lastUpdateTime: number;
    // agentId?: string;
    // roleType?: number;
}

export const useUserStore = defineStore({
    id: 'app-user',
    persist: {
        strategies: [
            {
                paths: ['userInfo', 'token', 'roleList'],
            },
        ],
    },
    state: (): UserState => ({
        // user info
        userInfo: null,
        // token
        token: undefined,
        // roleList
        roleList: [],
        // Whether the login expired
        sessionTimeout: false,
        // Last fetch time
        lastUpdateTime: 0,
    }),
    getters: {
        getUserInfo(): UserInfo {
            return this.userInfo || ({} as UserInfo);
        },
        getToken(): string {
            return this.token as string;
        },
        getRoleList(): RoleEnum[] {
            return this.roleList.length > 0 ? this.roleList : [];
        },
        getSessionTimeout(): boolean {
            return !!this.sessionTimeout;
        },
        getLastUpdateTime(): number {
            return this.lastUpdateTime;
        },
    },
    actions: {
        setToken(info: string | undefined) {
            this.token = info ? info : ''; // for null or undefined value
        },
        setRoleList(roleList: RoleEnum[]) {
            this.roleList = roleList;
        },
        setUserInfo(info: UserInfo | null) {
            this.userInfo = info;
            this.lastUpdateTime = new Date().getTime();
        },
        setSessionTimeout(flag: boolean) {
            this.sessionTimeout = flag;
        },
        resetState() {
            this.userInfo = null;
            this.token = '';
            this.roleList = [];
            this.sessionTimeout = false;
        },
        /**
         * @description: login
         */
        async login(
            params: LoginParams & {
                goHome?: boolean
                mode?: ErrorMessageMode
            },
        ): Promise<GetUserInfoModel | null> {
            try {
                const {goHome = true, mode, ...loginParams} = params;

                const data = await loginApi(loginParams, mode);
                const {token, schoolId} = data;

                localStorage.setItem('schoolId', schoolId);

                getSchoolNameApi({schoolId}).then(res => {
                    if (res?.schoolName) localStorage.setItem('schoolName', res.schoolName);
                    if (res?.schoolLogo) localStorage.setItem('schoolLogo', res.schoolLogo);
                    if (res?.bgColor) localStorage.setItem('bgColor', res.bgColor);
                });


                // save token
                this.setToken(token);
                return this.afterLoginAction(goHome);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async afterLoginAction(goHome?: boolean): Promise<GetUserInfoModel | null> {
            if (!this.getToken) {
                return null;
            }
            // get user info
            const userInfo = await this.getUserInfoAction();

            const sessionTimeout = this.sessionTimeout;
            if (sessionTimeout) {
                this.setSessionTimeout(false);
            } else {
                const permissionStore = usePermissionStore();
                if (!permissionStore.isDynamicAddedRoute) {
                    const routes = await permissionStore.buildRoutesAction();
                    routes.forEach((route) => {
                        router.addRoute(route);
                    });
                    router.addRoute(PAGE_NOT_FOUND_ROUTE);
                    permissionStore.setDynamicAddedRoute(true);
                }
                goHome &&
                (await router.replace(userInfo?.homePath || PageEnum.BASE_HOME));
            }
            return userInfo;
        },

        async getUserInfoAction(): Promise<UserInfo | null> {
            if (!this.getToken) {
                return null;
            }

            const userInfo = await getUserInfo();
            const {roles = []} = userInfo;
            if (isArray(roles)) {
                const roleList = roles.map((item) => item.value) as RoleEnum[];
                this.setRoleList(roleList);
            } else {
                userInfo.roles = [];
                this.setRoleList([]);
            }
            this.setUserInfo(userInfo);
            return userInfo;
        },
        /**
         * @description: logout
         */
        async logout(goLogin = false) {
            /*      if (this.getToken) {
                    await doLogout().catch(() => {
                      console.log('注销Token失败')
                    })
                  }*/
            this.setToken(undefined);
            this.setSessionTimeout(false);
            this.setUserInfo(null);
            goLogin && router.push(PageEnum.BASE_LOGIN);
        },

        /**
         * @description: Confirm before logging out
         */
        confirmLoginOut() {
            const {createConfirm} = useMessage();
            const {t} = useI18n();
            createConfirm({
                iconType: 'warning',
                title: () => h('span', t('sys.app.logoutTip')),
                content: () => h('span', t('sys.app.logoutMessage')),
                onOk: async () => {
                    await this.logout(true);
                    localStorage.clear();
                    await localForage.clear();
                },
            });
        },
    },
});

// Need to be used outside the setup
export function useUserStoreWithOut() {
    return useUserStore(pinia);
}
