export * from './breakpoint'
export * from './http'
export * from './menu'

export enum ContentLayoutEnum {
  // auto width
  FULL = 'full',
  // fixed width
  FIXED = 'fixed',
}

// menu theme enum
export enum ThemeEnum {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum SettingButtonPositionEnum {
  AUTO = 'auto',
  HEADER = 'header',
  FIXED = 'fixed',
}

export enum SessionTimeoutProcessingEnum {
  ROUTE_JUMP,
  PAGE_COVERAGE,
}

/**
 * 权限模式
 */
export enum PermissionModeEnum {
  // HomeworkListTable
  ROLE = 'ROLE',
  // black
  BACK = 'BACK',
  // route mapping
  ROUTE_MAPPING = 'ROUTE_MAPPING',
}

export enum RoleEnum {
  // self
  SUPER = 'super',
  ADMIN = 'admin',
  ITEMCUTTER = 'itemCutter',
  ITEMADMIN = 'itemAdmin',
  //agent
  AGENTSUPER = 'agentSuper',
  AGENTADMIN = 'agentAdmin',
  // school
  SCHOOLMASTER = 'schoolMaster',
  SCHOOLADMIN = 'schoolAdmin',
  SCHOOLGRADEADMIN = 'schoolGradeAdmin',
  SCHOOLTEACHER = 'schoolTeacher',

  // Teacher
  T1 = 'T1',// 年级教师
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7',
  T8 = 'T8',
  T9 = 'T9',
  T0 = 'T10',
  T11 = 'T11',
  T12 = 't12',

  // grade admin
  A1 = 'A1',// 年级管理员
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  A7 = 'A7',
  A8 = 'A8',
  A9 = 'A9',
  A10 = 'A10',
  A11 = 'A11',
  A12 = 'A12',

  AUDIT = 'audit',// 题目审核权限
  STUDENT = 'student',
  PRINTAGENT = 'printAgent',


  // tester
  TEST = 'test',
}

export enum PageEnum {
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_HOME = '/dashboard',
  // error page path
  ERROR_PAGE = '/exception',
  // error log page path
  ERROR_LOG_PAGE = '/error-log/list',
  EDIT = '/edit',
}

export enum ComponentSizeEnum {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
}

export enum ComponentSizeValueEnum {
  DEFAULT = 48,
  SMALL = 16,
  LARGE = 64,
}

export enum ErrorTypeEnum {
  VUE = 'vue',
  SCRIPT = 'script',
  RESOURCE = 'resource',
  AJAX = 'ajax',
  PROMISE = 'promise',
}
