<template>
    <div id="logoBox" @click="goHome">
        <img :src="'https://oss.zhi-tu.net/tiku/img/' + logo " v-if="logo"/>
        <div
            class="flex  justify-center text-base text-gray-50 pt-2"
            v-if="!logo"
        >
            {{ title }}
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed, unref} from 'vue';
// import { getGlobalConfig } from '@/internal'
import {useGo} from '@/hooks/web/usePage';
import {useMenuSetting} from '@/hooks/setting/useMenuSetting';
import {useDesign} from '@/hooks/web/useDesign';
import {PageEnum} from '@pkg/tokens';
import {useUserStore} from '@/store/user';

const props = defineProps({
    /**
     * The theme of the current parent component
     */
    theme: {
        type: String,
        validator: (v: string) => ['light', 'dark'].includes(v),
    },
    /**
     * Whether to show title
     */
    showTitle: {type: Boolean, default: true},
    /**
     * The title is also displayed when the menu is collapsed
     */
    alwaysShowTitle: {type: Boolean},
});

const {prefixCls} = useDesign('app-logo');
const {getCollapsedShowTitle} = useMenuSetting();
const userStore = useUserStore();
const title = localStorage.getItem('schoolName');
const logo = localStorage.getItem('schoolLogo');
let bgColor = localStorage.getItem('bgColor');
if (!bgColor) bgColor = '#001628';

const go = useGo();

const getAppLogoClass = computed(() => [
    prefixCls,
    props.theme,
    {'collapsed-show-title': unref(getCollapsedShowTitle)},
]);

/*const getTitleClass = computed(() => [
  `${prefixCls}__title`,
  {
    'xs:opacity-0': !props.alwaysShowTitle,
  },
])*/

function goHome() {
    go(userStore.getUserInfo.homePath || PageEnum.BASE_HOME);
}
</script>
<style lang="less" scoped>
@prefix-cls: ~'@{namespace}-app-logo';

.@{prefix-cls} {
    display: flex;
    align-items: center;
    padding-left: 7px;
    cursor: pointer;
    transition: all 0.2s ease;

    &.light {
        border-bottom: 1px solid @border-color-base;
    }

    &.collapsed-show-title {
        padding-left: 20px;
    }

    &.light &__title {
        color: @primary-color;
    }

    &.dark &__title {
        color: @white;
    }

    &__title {
        font-size: 16px;
        font-weight: 700;
        transition: all 0.5s;
        line-height: normal;
    }
}

.vben-layout-menu-logo {
    padding: 0;
    //background-color: #AA370A;
}

.vben-layout-menu-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    //background-color:#AA370A ;
}

#logoBox {
    background: v-bind(bgColor);
}
</style>
